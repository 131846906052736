/* styles/Homepage.css */
.video {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: black;
}

video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  pointer-events: none;
}

.main > h1 {
  font-size: 3rem; /* Default font size for large screens */
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: 15px;
  font-family: sans-serif;
}

.main > p {
  color: #90898b;
  font-size: 1.125rem; /* Default font size for large screens */
  font-weight: 400;
  line-height: 1.5;
  max-width: 500px;
  margin: 0 auto 45px auto;
  text-align: center;
  font-family: sans-serif;
}

.video.mobile {
  display: block;
}

.video.web {
  display: none;
}

/* Responsive font sizes */
@media (max-width: 1200px) {
  .main > h1 {
    font-size: 2.5rem; /* Slightly smaller font size for medium screens */
  }
  .main > p {
    font-size: 1rem; /* Slightly smaller font size for medium screens */
  }
}

@media (max-width: 768px) {
  .main > h1 {
    font-size: 2rem; /* Smaller font size for small screens */
  }
  .main > p {
    font-size: 0.875rem; /* Smaller font size for small screens */
  }
}

@media (max-width: 576px) {
  .main > h1 {
    font-size: 1.75rem; /* Even smaller font size for extra small screens */
  }
  .main > p {
    font-size: 0.75rem; /* Even smaller font size for extra small screens */
  }
}

@media (min-width: 768px) {
  .video.mobile {
    display: none;  /* Hides the mobile video on web */
  }

  .video.web {
    display: block;  /* Displays the web video on larger screens */
  }
}

/* styles/WhatWeDo.css */

.custom-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.2s ease-in-out;
}

.custom-card:hover {
  transform: translateY(-10px);
}

.custom-card-img-container {
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (56.25% = 9/16 * 100) */
  position: relative;
}

.custom-card-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.custom-card-body {
  padding: 0.5rem 0.5rem 1rem 0.5rem;
  text-align: center;
}

.custom-card-title {
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
}

.custom-card-description {
  font-size: 1rem;
  margin-bottom: 10px;
  color: #666;
}

.custom-card-button {
  border: 0;
  background-color: #2585d1;
  padding: 7px 15px;
  border-radius: 15px;
  cursor: pointer;
  font-family: sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: white;
  transition: all 0.3s ease-out 0s;
}

.custom-card-button:hover {
  background-color: #1a5d96;
}

@media (max-width: 992px) {
  .custom-card-title {
    font-size: 1rem;
  }

  .custom-card-description {
    font-size: 0.8rem;
  }

  .custom-card-button {
    font-size: 0.8rem;
  }
}

@media (max-width: 768px) {
  .custom-card-title {
    font-size: 1rem;
  }

  .custom-card-description {
    font-size: 0.9rem;
  }

  .custom-card-button {
    font-size: 0.9rem;
  }
}

@media (max-width: 576px) {
  .custom-card-title {
    font-size: 0.9rem;
  }

  .custom-card-description {
    font-size: 0.8rem;
  }

  .custom-card-button {
    font-size: 0.8rem;
  }
}

/* styles/Subpage.css */

.blog-card {
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  margin: 16px auto; /* Adjust margin for better spacing */
  border: 0;
  max-width: 100%; /* Make the max-width 100% */
  width: 100%; /* Ensure it takes full width */
}

.image-container {
  width: 150px;
  height: 150px;
  flex-shrink: 0; /* Prevent image from shrinking */
}

.image-container img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.content {
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  flex-grow: 1; /* Allow content to grow */
}

.content h2 {
  margin: 0;
  font-size: 30px;
  font-weight: 400;
  color: black;
  font-family: serif;
  line-height: 45px;
}

.content p {
  color: #555;
  margin-bottom: 0.5rem;
  font-family: sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 27px;
}

.read-more {
  color: #1eafed;
  text-decoration: none;
  transition: 0.3s all ease;
  font-family: sans-serif;
  font-size: 15px;
  line-height: 27px;
  font-weight: 400;
  cursor: pointer;
}
.read-more svg {
  font-size: 12px;
}
.read-more:hover {
  color: #117ce6;
  font-size: 17px;
}

@media (max-width: 600px) {
  .blog-card {
    flex-direction: column;
    align-items: center;
    padding: 0 16px; /* Add padding to the sides */
  }
  .content {
    align-items: center;
    text-align: center;
  }
}

/* styles/Blog.css */

.blog-container {
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
}

.blog-heading {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #333;
}

.blog-paragraph {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  color: #555;
}

.blog-features {
  list-style-type: disc;
  padding-left: 1.5rem;
  margin-bottom: 1.5rem;
  color: #555;
}

.blog-features li {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 0.5rem;
}

.blog-image {
  width: 100%;
  height: auto;
  margin-top: 2rem;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .blog-heading {
    font-size: 2rem;
  }

  .blog-paragraph,
  .blog-features li {
    font-size: 0.9rem;
  }
}

/* styles/Contactus.css */

.contact-container {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  margin-top: 100px;
  margin-bottom: 50px;
}

.contact-container h1 {
  font-size: 2.5em;
  color: #333;
}

.contact-container p {
  font-size: 1.2em;
  color: #666;
}

.contact-info {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.contact-info h2 {
  font-size: 1.8em;
  color: #333;
  margin-bottom: 20px;
}

.contact-info p {
  font-size: 1.1em;
  color: #555;
}

.contact-form {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.contact-form .form-group {
  margin-bottom: 20px;
}

.contact-form .form-group label {
  font-size: 1.1em;
  color: #333;
  margin-bottom: 10px;
}

.contact-form .form-control {
  width: 100%;
  padding: 10px;
  font-size: 1.1em;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.contact-form .form-control:focus {
  border-color: #2585d1;
  box-shadow: 0 0 8px rgba(37, 133, 209, 0.1);
}

.contact-form .btn {
  background-color: #2585d1;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.1em;
}

.contact-form .btn:hover {
  background-color: #1d6fb7;
}

/* about us */

.about-us-container {
  padding: 0 15px;
  margin: 100px 0px 50px 0px;
}

.about-us-container h2 {
  font-size: 40px;
}

.img-square {
  width: 100%;
  max-width: 450px; /* Set the max width */
  height: 450px; /* Set the height */
  object-fit: cover; /* Ensure the image covers the square area */
  margin: 0 auto; /* Center the image horizontally */
}

.about-us-container .mission h2 {
  font-size: 70px;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-weight: 900;
  margin: 0 !important;
}

@media (max-width: 768px) {
  .img-square {
    max-width: 100%;
    height: auto; /* Ensure the image maintains its aspect ratio */
  }

  .mission {
    text-align: center; /* Center the text on smaller screens */
  }

  .mission h2 {
    font-size: 50px; /* Adjust the font size for smaller screens */
  }

  .row.align-items-center {
    flex-direction: column;
    text-align: center;
  }

  .row.align-items-center .col-md-7,
  .row.align-items-center .col-md-5 {
    width: 100%;
    margin-bottom: 1.5rem;
  }

  .row.align-items-center .col-md-7 {
    order: 2;
  }

  .row.align-items-center .col-md-5 {
    order: 1;
  }
}

.board-member {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.board-member-img {
  width: 150px;
  height: 150px;
  margin-right: 2rem;
  border-radius: 10px;
  object-fit: cover;
}

.board-member-info {
  flex: 1;
}

@media (max-width: 768px) {
  .board-member {
    flex-direction: column;
    align-items: center;
  }

  .board-member-img {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .board-member-info {
    text-align: center;
  }
}

.person-card {
  margin: 1em 0;
  /* border: 1px solid #e0e0e0; */
  /* border-radius: 10px; */
  border: none !important;
  border-radius: 0px !important;
  overflow: hidden;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  transition: transform 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.person-card img {
  width: 100%;
}
.person-card .card-body {
  padding: 0px !important;
}

.person-card .card-title {
  font-size: 13px;
}

.team-member-card {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.team-member-card img {
  width: 300px;
  height: 300px;
  border-radius: 100%;
  margin-top: 10px;
  object-fit: cover;
  align-self: center;
}

.person-card:hover,
.team-member-card:hover {
  transform: translateY(-5px);
}

.team-member-card .card-body {
  padding: 1.5rem;
}

.team-member-card .card-title {
  font-size: 1rem;
  font-weight: bold;
}

.team-member-card .card-subtitle {
  font-size: 0.875rem;
  color: rgb(105, 105, 207) !important;
}

.team-member-card .card-text {
  font-size: 0.75rem;
  color: #6c757d;
}

/* research */
.main {
  max-width: 1200px;
  margin: auto;
}

.research-card {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  margin: 1rem 0;
  padding: 1rem;
  background-color: #fff;
  text-align: center;
}

.research-card a {
  color: black;
  text-decoration: none;
}

.research-card img {
  width: 100%;
  height: 400px; /* Adjust the height as needed */
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 1rem;
}

.research-card:hover {
  cursor: pointer;
  transform: translateY(-5px);
}

.research-card h2 {
  font-size: 1.5rem;
}

/* scientist */
.scientist-container {
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
}

.scientist-heading {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #333;
}

.scientist-paragraph {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  color: #555;
}

@media (max-width: 768px) {
  .scientist-heading {
    font-size: 2rem;
  }

  .scientist-paragraph {
    font-size: 0.9rem;
  }
}

/* paper */
.paper-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.paper-card > div > div {
  display: flex;
  gap: 30px;
}
.paper-card h1 {
  text-align: left;
  font-size: 18px;
  font-weight: 400;
  width: 80%;
}
.paper-card h3 {
  font-size: 12px;
  font-weight: 400;
  color: rgb(105, 105, 207) !important;
}
.paper-card a {
  border: 0;
  background-color: #2585d1;
  padding: 5px 31px;
  border-radius: 30px;
  cursor: pointer;
  font-family: sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 22.5px;
  color: white;
  transition: all 0.3s ease-out 0s;
  text-decoration: none;
}
.paper-card button:hover {
  background-color: #2584d1b3;
}
@media (max-width: 768px) {
  .paper-card h1 {
    width: 90%;
  }
}

.news h2 {
  font-size: 40px !important;
}
